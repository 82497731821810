import React, { useState, useEffect } from 'react'

const SingleColor = ({ rgb, weight, index, hex }) => {
    const [alert, setAlert] = useState(false);
    const bcg = rgb.join(',');
    const hexValue = `#${hex}`;
    useEffect(() => {
        const interval = setInterval(() => {
            setAlert(false);
        }, 3000)
        return () => clearInterval(interval);
    })
    const onClick = () => {
        setAlert(true);
        navigator.clipboard.writeText(hexValue);
    }
    return (
        <article className={`color ${index > 10 && 'color-light'}`} style={{backgroundColor: `rgb(${bcg})`}} onClick={onClick} >
            <p className='percent-value' >
                {weight}%
            </p>
            <p className='color-value'>
                {hexValue}
            </p>
            {
                alert && <p className='alert'>
                    copied to clipboard
                </p>
            }
        </article>
    )
}

export default SingleColor