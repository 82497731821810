import React, { useState } from 'react'
import SingleColor from './SingleColor'

import Values from 'values.js'
function App() {
  const [color, setColor] = useState('');
  const [error, setError] = useState(false);
  const [list, setList] = useState(new Values('#f15025').all(1));

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      let colors = new Values(color).all(10);
      setError(false)
      setList(colors)
    }catch(error) {
      console.log(e);
      setError(true);
    }
  }
  return (
    <>
      <section className='container' >
        <h3>Color Generator</h3>
        <form onSubmit={handleSubmit} >
          <input type={'text'} value={color} onChange={(e) => setColor(e.target.value)} 
          placeholder='#1BA7E3 '
          className={`${error ? 'error' : null}`}
           />
          <button className='btn' type='submit'>Submit</button>
        </form>
      </section>
      <section className='colors' >
        {
          list.map((color, index) => {
            console.log(color);
            return <SingleColor key={index} {...color} index={index} hex={color.hex} />
          })
        }
      </section>
    </>
  )
}

export default App